
import Vue from 'vue';
import { AxiosError } from 'axios';
import NimsStep03Template from '@/templates/nims/NimsStep03Template.vue';
import { getNimsOrderInfoProxy, getMyGabiaServiceManagersProxy } from '@/api/gabia-proxy/index';
import { MygabiaManager } from '@/api/gabia-proxy/types';
import { NimsGroup } from '@/api/infra-renew/types';
import {
  getGroupInfo,
  putGroupInfo,
  getGroupManagerList,
  putManagerInfo,
  putNimsSettingInfo,
} from '@/api/infra-renew/index';
import { moveToFinalPage } from '@/utils';

export default Vue.extend({
  name: 'NimsStep03Brain',
  components: { NimsStep03Template },
  props: {},
  data() {
    return {
      serviceType: '',
      hostingSeqNo: '',
      accessToken: '',
      orderNum: '',
      groupSeqNo: 0,
      idcArea: '9' as '1' | '9',
      settingMethod: 'R' as 'R' | 'S',
      codeArr: [] as string[],
      codeArrDepth2: [] as string[],
      seqNoArr: [] as number[],
      goodIdNoArr: [] as number[],
      isOneMinuteSever: false,
      groupInfo: {} as NimsGroup,
      isRender: false,
      isCopyState: false,
      formState: {
        billManager: {
          name: '',
          emailId: '',
          domain: '',
          domainSelect: '',
          phone: '',
          mobile: '',
        },
        serviceManager: {
          name: '',
          emailId: '',
          domain: '',
          domainSelect: '',
          phone: '',
          mobile: '',
        },
      },
      serviceManangerNo: 0,
      billManangerNo: 0,
      mygabiaManagers: [] as MygabiaManager[],
    };
  },
  computed: {
    userId(): string {
      return this.$store.state.userInfo?.user_id ?? '';
    },
  },
  watch: {},
  async mounted() {
    this.hostingSeqNo = this.$route.query.hst_no as string;
    await this.getNimsOrderInfo();
    await this.getGroupInfoFnc();
    await this.getGroupManagers();
    await this.getMyGabiaManagers();
    this.settingUiChecker();
    this.$GlobalLoading.hide();
    this.isRender = true;
  },
  methods: {
    async recallManager() {
      return await this.getMyGabiaManagers();
    },
    async getGroupInfoFnc() {
      try {
        const { data } = await getGroupInfo(this.groupSeqNo);
        this.groupInfo = data.data;
      } catch (error) {
        console.warn(error);
      }
    },
    async goNextStep(payload) {
      try {
        this.$GlobalLoading.show();
        if (!this.groupInfo.group_expire_date || this.groupInfo.group_expire_date === '9999-12-31 00:00:00') {
          //! 그룹이 디폴트 그룹 (생성하면서 온 경우)
          await putGroupInfo(this.groupSeqNo, { group_name: payload.group_name });
          await putManagerInfo(this.groupSeqNo, this.serviceManangerNo, {
            manager_name: this.formState.serviceManager.name,
            manager_email: `${this.formState.serviceManager.emailId}@${this.formState.serviceManager.domain}`,
            manager_phone: this.formState.serviceManager.phone,
            manager_mobile: this.formState.serviceManager.mobile,
          });
          await putManagerInfo(this.groupSeqNo, this.billManangerNo, {
            manager_name: this.formState.billManager.name,
            manager_email: `${this.formState.billManager.emailId}@${this.formState.billManager.domain}`,
            manager_phone: this.formState.billManager.phone,
            manager_mobile: this.formState.billManager.mobile,
          });
        }

        if (this.serviceType) {
          //! 서버 설정바꿀건 없는데 그룹 디폴트 설정만 바꾸는경우 대비
          const params = {
            contract_group_seqno: this.groupSeqNo,
            idc: this.idcArea,
            server: {
              install_type: this.settingMethod,
            },
          };

          const queryStr = this.seqNoArr.map((seqno) => `goods_seqno[]=${seqno}`).join('&');

          await putNimsSettingInfo(this.groupSeqNo, queryStr, params);
        }

        alert('신청정보 변경을 완료 하였습니다.');
        this.gotoFinalPage();
      } catch (error) {
        const errorResponse = (error as AxiosError)?.response?.data || {
          message: '불특정 에러가 발생했습니다.',
        };
        alert(`goNextStep: ${errorResponse.message}`);
      } finally {
        this.$GlobalLoading.hide();
      }
    },
    async getMyGabiaManagers() {
      if (this.groupInfo.group_expire_date !== '9999-12-31 00:00:00') {
        return;
      }

      try {
        const { data } = await getMyGabiaServiceManagersProxy({
          uri: '/members/servicemanager/list',
          'data[user_id]': this.userId,
        });
        this.mygabiaManagers = data.data;
      } catch (error) {
        const errorResponse = (error as AxiosError)?.response?.data || {
          message: '불특정 에러가 발생했습니다.',
        };
        alert(`getMyGabiaManagers: ${errorResponse.message}`);
      } finally {
        this.$GlobalLoading.hide();
      }
    },
    async getNimsOrderInfo() {
      try {
        const params = {
          uri: `/v5/payment/${this.hostingSeqNo}`,
          'data[response_option[with_goods_info]]': 'Y',
        };

        const { data } = await getNimsOrderInfoProxy(params);
        this.codeArr = data.settlement_list.map((item) => {
          return item.settlement_goods_list[0].goods?.classification?.parent_classification?.code || 'ERROR';
        });

        this.codeArrDepth2 = data.settlement_list.map((item) => {
          return item.settlement_goods_list[0].goods?.classification?.code || 'ERROR';
        });

        this.orderNum = data.ordernum;
        this.groupSeqNo = data.settlement_list[0].integrated_service_seqno;
        this.seqNoArr = data.settlement_list.map((item) => {
          return item.service_seqno || 0;
        });

        console.log(this.goodIdNoArr);
      } catch (error) {
        console.log(error);
      }
    },
    async getGroupManagers() {
      if (this.groupInfo.group_expire_date !== '9999-12-31 00:00:00') {
        return;
      }

      try {
        const { data } = await getGroupManagerList(this.groupSeqNo);
        const billManager = data.data.filter((item) => item.represent_flag === 'Y' && item.manager_kind.code === 'B');
        const serviceManager = data.data.filter(
          (item) => item.represent_flag === 'Y' && item.manager_kind.code === 'S',
        );

        if (serviceManager.length) {
          this.formState.serviceManager.name = serviceManager[0].manager_name || '';
          this.formState.serviceManager.emailId = serviceManager[0].manager_email.split('@')[0] || '';
          this.formState.serviceManager.domain = serviceManager[0].manager_email.split('@')[1] || '';
          this.formState.serviceManager.mobile = serviceManager[0].manager_mobile || '';
          this.formState.serviceManager.phone = serviceManager[0].manager_phone || '';
          this.serviceManangerNo = serviceManager[0].seqno;
        }

        if (billManager.length) {
          this.formState.billManager.name = billManager[0].manager_name || '';
          this.formState.billManager.emailId = billManager[0].manager_email.split('@')[0] || '';
          this.formState.billManager.domain = billManager[0].manager_email.split('@')[1] || '';
          this.formState.billManager.mobile = billManager[0].manager_mobile || '';
          this.formState.billManager.phone = billManager[0].manager_phone || '';
          this.billManangerNo = billManager[0].seqno;
        }
      } catch (error) {
        const errorResponse = (error as AxiosError)?.response?.data || {
          message: '불특정 에러가 발생했습니다.',
        };
        alert(`getGroupBillManagerInfo: ${errorResponse.message}`);
      }
    },
    settingUiChecker() {
      //! 1분서버 체크
      if (this.codeArrDepth2.includes('ONESRV')) {
        this.isOneMinuteSever = true;
      }

      if (this.codeArr.includes('EQUIPMENT')) {
        this.serviceType = 'server-hosting';
      } else if (this.codeArr.includes('RACKSPACE')) {
        this.serviceType = 'corocation';
      } else if (this.groupInfo.group_expire_date === '9999-12-31 00:00:00') {
        //! 이경우 그룹 세팅이라도 진행해야함
        this.serviceType = '';
      } else {
        alert('신청정보 확인완료하였습니다. 정보확인 페이지로 이동합니다.');
        this.gotoFinalPage();
      }
    },
    changeManagerFromTemplate(params) {
      this.formState[params.flag] = params.payload;

      if (this.isCopyState) {
        this.formState.serviceManager = params.payload;
      }
    },
    myGabiaManagerInfoSetting(payload) {
      console.log(payload);
      const manager = this.mygabiaManagers.filter((item) => {
        return item.seqno === payload.seqNo;
      });

      this.formState[payload.type] = {
        name: manager[0].hanname,
        emailId: manager[0].email.split('@')[0] || '',
        domain: manager[0].email.split('@')[1] || '',
        phone: manager[0].phone,
        mobile: manager[0].hp_no,
        domainSelect: '',
      };

      if (this.isCopyState) {
        this.formState.serviceManager = { ...this.formState.billManager };
      }
    },
    copyBillManager(isCopyState: boolean) {
      this.isCopyState = isCopyState;
      this.formState.serviceManager = { ...this.formState.billManager };
    },
    gotoFinalPage() {
      moveToFinalPage({
        ordernum: this.orderNum,
      });
    },
  },
});
